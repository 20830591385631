@import "/src/styles/variables";
.button__wrapper {
  background-color: $background-blue-hover;
  width: 180px;
  border-radius: 16px;

  .btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

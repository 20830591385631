@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import "../styles/fonts.scss";

$background-black: rgba(0, 0, 0, 1);
$background-light-dark: rgba(143, 141, 141, 0.598);
$background-white: #ffffff;
$background-blue: #1762ce;
$background-dark-blue: #171c40;
$background-blue-hover: rgba(23, 98, 206, 0.5);
$font-color-primary: #fff;
$font-color-secondary: rgba(255, 255, 255, 0.8);
$border-color-white: rgba(255, 255, 255, 0.8);
$font-links: "Open Sans", sans-serif;
$heading: "TomatoBold";
$sub-heading: "TomatoRegular";
$body: "TomatoMedium";
$transition: all 400ms ease;

$container-width-lg: 90vw;
$container-width-md: 86vw;
$container-width-sm: 90vw;

// ============= GENERAL STYLES ============= //
.container {
  width: $container-width-lg;
  margin: 0 auto;
}

section {
  margin-top: 10rem;
}
// section > h1,
// h2,
// h5,
// h6 {
// }

// ============= media queries (medium devices) ============= //
@media screen and (max-width: 1024px) {
  .container {
    width: $container-width-md;
  }

  section {
    margin-top: 6rem;
  }
}

// ============= media queries (small devices) ============= //
@media screen and (max-width: 600px) {
  .container {
    width: $container-width-sm;
  }

  //   .section {
  //     margin-top: 6rem;
  //   }
}

@import "/src/styles/variables";

.loader__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: $background-black;
  top: 0;
  left: 0;
  z-index: 2000;

  .loader {
    width: 90vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import "/src/styles/variables";

.footer__wrapper {
  .footer__container {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copyright {
      margin-top: 12rem;
    }

    span {
      display: flex;
      font-size: 0.8rem;
      color: $font-color-secondary;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .footer__links {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}

@font-face {
  font-family: "TomatoBold";
  src: url("../fonts/TomatoGrotesk-Bold.otf");
}

@font-face {
  font-family: "TomatoMedium";
  src: url("../fonts/TomatoGrotesk-Medium.otf");
}

@font-face {
  font-family: "TomatoRegular";
  src: url("../fonts/TomatoGrotesk-Regular.otf");
}

@font-face {
  font-family: "TomatoLight";
  src: url("../fonts/TomatoGrotesk-Light.otf");
}

@import "/src/styles/variables";

.variant-h1 {
  font-size: 4rem;
  font-family: $heading;
}
.variant-h2 {
  font-size: 3.7rem;
  font-family: $heading;
}
.variant-h3 {
  font-size: 3rem;
  font-family: $heading;
}
.variant-h4 {
  font-size: 2.6rem;
  font-family: $heading;
}
.variant-h5 {
  font-size: 2rem;
  font-family: $sub-heading;
}
.variant-h6 {
  font-size: 1rem;
  font-family: $sub-heading;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline;
  line-height: 20px;
  text-align: left;
}
.variant-body1 {
  font-size: 1.2rem;
  font-family: $body;
}
.variant-body2 {
  font-size: 0.8rem;
  font-family: $body;
}

@import "/src/styles/variables";
.contact-f__wrapper {
  background: url("../../img/gip-bg.gif");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .contact-f__container {
    width: 90vw;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    //   padd
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2rem;
      h4 {
        font-size: 3rem;
      }
    }
    //   pad end

    //   mobile
    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2rem;
      h4 {
        font-size: 2rem;
      }
    }
    //   mobile end
  }
}

@import "/src/styles/variables";

.navigation__wrapper {
  width: 100vw !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: $background-black;
  z-index: 100;
  margin-top: 0;

  .navigation__container {
    width: 90vw;
    height: 8vh;
    display: flex;
    gap: 3rem;

    //   padd
    @media screen and (max-width: 600px) {
      width: 90vw;
    }
    //   pad end

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 42.69px;
        height: 40px;
      }
    }

    .nav__menu {
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3rem;

      a.active {
        color: $background-blue;
      }
    }
  }
}

@import "/src/styles/variables";
@import "/src/styles/fonts.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $background-black;
  font-family: $body;
  font-size: 16px;
  line-height: 1.7;
  color: $font-color-primary;
  margin: 0;
  padding: 0;
  min-width: fit-content;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $font-color-secondary;
  font-family: $font-links;
  font-size: 1em;
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: $background-blue-hover;
}

::-webkit-scrollbar-thumb {
  background-color: $font-color-secondary;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

// ============= GENERAL STYLES ============= //
